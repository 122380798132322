import $ from "jquery";
import slick from "slick-carousel";
import mask from "jquery-mask-plugin";
import remodal from "remodal";

$(document).ready(function(){
  $(".advantages-photo-slider").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    variableWidth: true,
    prevArrow:"<div class='prevArrow'></div>",
    nextArrow:"<div class='nextArrow'></div>"
  });

  $(".finished-projects-slider").slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '60px',
    variableWidth: true,
    prevArrow:"<div class='prevArrow'></div>",
    nextArrow:"<div class='nextArrow'></div>"
  });

  $('input[type="tel"]').mask('+7(000)000-00-00');

  $(".js-order-form").submit(function(e) {
    e.preventDefault();
    let form = this;
    $.ajax({
        url:     "form_handler.php", 
        type:     "POST", 
        dataType: "html", 
        data: $(this).serialize(),  
        success: function(response) {
          var orderModal = $('[data-remodal-id="order-modal"]').remodal();
          var thanksModal = $('[data-remodal-id="thanks-modal"]').remodal();
          if(orderModal.getState() === 'opened') {
            orderModal.close();
            thanksModal.open();
          }
          let button = $(form).find('.order-form-btn');
          let header = $(form).siblings('.order-form-header');
          setTimeout(() => {
            header.text("Спасибо за заявку!")
            button.text(":)");
  
          }, 500);
          button.attr('disabled', true);
      },
      error: function(response) {
        console.log("ОШИБКА");
      }
    });
  });

  $(".menu-open").on("click", function() {
    if (!$(".view").is("[data-page=index]")) {
      $(".back").slideToggle();
    }
    $(this).toggleClass("on");
    $(".mobile-menu").slideToggle();
  });

  $(".mobile-menu a").on("click", function() {
    if ($(this).attr("href")) {
      $(".mobile-menu").fadeOut();
      $(".menu-open").removeClass("on");

      if (!$(".view").is("[data-page=index]")) {
        $(".back").fadeIn();
      }
    }
  });
});